import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {CiBookmark, CiBookmarkCheck} from "react-icons/ci";
import ShineBorder from "../../../../ui/shine-border";
import {IoClose} from "react-icons/io5";
import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {getRelativeTime} from "../../../utils/dateUtils";
import {Link, useNavigate} from "react-router-dom";
import {LuExternalLink} from "react-icons/lu";
import ImageComponent from "../../../utils/ImageComponent";
import {API_BASE_URL} from "../../../../config";
import {BsHeart, BsHeartFill} from "react-icons/bs";
import CommentPlaygroundDetail from "./CommentPlaygroundDetail";
import {useCookies} from "../../../../cookieContext";
import createDOMPurify from "dompurify";

const HeaderDetail = ({username, item, fetchData, playgroundTags}) => {
    const [listOfCommentVisible, setListOfCommentVisible] = useState(false);
    const [showFullContent, setShowFullContent] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [followers, setFollowers] = useState(0);
    const [isUserLiked, setIsUserLiked] = useState(true);
    const [likeCount, setLikeCount] = useState(0);
    const [isSaved, setIsSaved] = useState(false);
    const [commentList, setCommentList] = useState([])
    const [viewCount, setViewCount] = useState(0);


    const {cookies} = useCookies();
    const emailLocal = cookies.email;
    const tokenLocal = cookies.token;
    const navigate = useNavigate();

    const handleSaveAction = async (action) => {
        if (!item?.id || !tokenLocal) {
            navigate('/login');
            return;
        }

        const endpoint = action === 'save'
            ? `${API_BASE_URL}/api/v1/save-playground/edit/add`
            : `${API_BASE_URL}/api/v1/save-playground/edit/delete?playId=${item.id}&email=${emailLocal}`;

        const method = action === 'save' ? 'POST' : 'DELETE';
        const body = action === 'save' ? JSON.stringify({
            id: item.id,
            email: emailLocal
        }) : undefined;

        try {
            const response = await fetch(endpoint, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body
            });

            if (!response.ok) {
                throw new Error(`Error ${action === 'save' ? 'saving' : 'unsaving'} playground: ${response.status}`);
            }

            setIsSaved(action === 'save');
        } catch (err) {
            console.error(err);
        }
    }

    const handleLikeAction = async (action) => {
        if (!item?.id || !tokenLocal) {
            navigate('/login');
            return;
        }

        const endpoint = action === 'like'
            ? `${API_BASE_URL}/api/v1/like-playground/edit/add`
            : `${API_BASE_URL}/api/v1/like-playground/edit/delete?id=${item.id}&email=${emailLocal}`;

        const method = action === 'like' ? 'POST' : 'DELETE';
        const body = action === 'like' ? JSON.stringify({
            id: item.id,
            email: emailLocal
        }) : undefined;

        try {
            const response = await fetch(endpoint, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body
            });

            if (!response.ok) {
                throw new Error(`Error ${action === 'like' ? 'liking' : 'unliking'} playground: ${response.status}`);
            }

            setLikeCount(prevState => prevState + (action === 'like' ? 1 : -1));
            setIsUserLiked(action === 'like');
        } catch (err) {
            console.error(err);
        }
    }

    const handleFollowAction = async (action) => {
        if (!item?.user || !tokenLocal) {
            navigate('/login');
            return;
        }

        const endpoint = action === 'follow'
            ? `${API_BASE_URL}/api/v1/follow/edit/add`
            : `${API_BASE_URL}/api/v1/follow/edit/delete/${item.user.username}/${emailLocal}`;

        const method = action === 'follow' ? 'POST' : 'DELETE';
        const body = action === 'follow' ? JSON.stringify({
            follower: item.user.email,
            followed: emailLocal
        }) : undefined;

        try {
            const response = await fetch(endpoint, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body
            });

            if (!response.ok) {
                throw new Error(`Error ${action === 'follow' ? 'following' : 'unfollowing'} user: ${response.status}`);
            }

            setFollowers(prevState => prevState + (action === 'follow' ? 1 : -1));
            setIsFollowing(action === 'follow');
        } catch (err) {
            console.error(err);
        }
    };

    const toggleListOfComment = () => {
        setListOfCommentVisible(!listOfCommentVisible);
    };

    const toggleContentVisibility = () => {
        setShowFullContent(!showFullContent);
    };

    const relativeTime = getRelativeTime(item.updatedAt);

    const increaseViewCount = useCallback(async () => {
        const response = await fetchData(`${API_BASE_URL}/api/v1/playground/get/${item.id}`, 'Error increasing view count');
        setViewCount(item.viewCount + response)
    }, [item, fetchData]);

    const fetchCommentList = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/playground/comment/get/${item.id}`, 'Error fetching comments');
        setCommentList(data);
    }, [item, fetchData]);

    const fetchIsUserSaved = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/save-playground/get/check?playId=${item.id}&email=${emailLocal}`, 'Error fetching saved status');
        setIsSaved(data);
    }, [item, emailLocal, fetchData]);

    const fetchLikeCount = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/like-playground/get/count?id=${item.id}`, 'Error fetching like count');
        setLikeCount(data);

    }, [item, fetchData]);

    const fetchIsUserLiked = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/like-playground/get/check?id=${item.id}&email=${emailLocal}`, 'Error fetching like status');
        setIsUserLiked(data);

    }, [item, emailLocal, fetchData]);

    const fetchIsUserFollowing = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/follow/get/${item.user?.username}/${emailLocal}`, 'Error fetching follow status');
        setIsFollowing(data);
    }, [item, emailLocal, fetchData]);

    const fetchFollowers = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/follow/get/${username}`, 'Error fetching followers');
        setFollowers(data);
    }, [username, fetchData]);


    useEffect(() => {
        if (item && item.id) {
            void fetchFollowers();
            void fetchIsUserFollowing();
            void fetchIsUserLiked();
            void fetchLikeCount();
            void fetchIsUserSaved();
            void fetchCommentList();
            void increaseViewCount();
        }
    }, [item, fetchFollowers, fetchIsUserFollowing, fetchIsUserLiked, fetchLikeCount, fetchIsUserSaved, fetchCommentList, increaseViewCount]);

    let DOMPurify;

    if (typeof window === 'undefined') {
        // Server-side
        const jsdomModule = eval("require('jsdom')");
        const { JSDOM } = jsdomModule;
        const window = new JSDOM('').window;
        DOMPurify = createDOMPurify(window);
    } else {
        // Client-side
        DOMPurify = createDOMPurify(window);
    }

    const sanitizeHTML = (html) => ({
        __html: DOMPurify.sanitize(html)
    });

    return (
        <div className={"bg-[#A1A1AA10] text-[#F4F4F5] rounded-2xl"}>
            <div className="mx-auto">
                <h1 className="text-2xl font-bold mb-4 mx-4 pt-4">
                    <Link to={item.link} className={"flex gap-1 underline w-fit"} target="_blank"
                          rel="noopener noreferrer">
                        {item.title} <LuExternalLink className={"text-sm"}/>
                    </Link>
                </h1>

                <div className="play-detail-owner-render flex items-center mb-4 justify-between px-4">
                    <div className="play-detail-owner-render-left flex items-center mb-4 justify-between">
                        <div className="flex items-center">
                            <ImageComponent image={item.user?.profileImageUrl} className={"w-10 h-10 mr-2"}
                                            alt={item.user?.displayName}/>
                            <div>
                                <Link to={`/user/${item.user?.username}`}
                                      className="font-semibold">{item.user?.displayName}</Link>
                                <p className="text-sm text-gray-400">{followers} followers</p>
                            </div>
                        </div>
                        {
                            !emailLocal ? (
                                <ShineBorder
                                    className={"ml-5 text-center px-4 py-2 capitalize opacity-50 cursor-not-allowed"}>Login
                                    to Follow</ShineBorder>
                            ) : item.user?.email === emailLocal ? (
                                <ShineBorder
                                    className={"ml-5 text-center px-4 py-2 capitalize opacity-50 cursor-not-allowed"}>Follow</ShineBorder>
                            ) : isFollowing ? (
                                <ShineBorder onClick={() => handleFollowAction('unfollow')}
                                             className={"ml-5 text-center px-4 py-2 capitalize cursor-pointer"}>Following</ShineBorder>
                            ) : (
                                <ShineBorder onClick={() => handleFollowAction('follow')}
                                             className={"ml-5 text-center px-4 py-2 capitalize cursor-pointer"}>Follow</ShineBorder>
                            )
                        }
                    </div>

                    <div className="flex space-x-2 mb-4">
                        <div className="bg-gray-700 rounded-full flex items-center px-4">
                            {
                                isUserLiked ?
                                    (
                                        <button onClick={() => handleLikeAction('dislike')}
                                                className="px-2 py-2 rounded-full flex items-center">
                                            <BsHeartFill/>
                                        </button>
                                    ) :
                                    (
                                        <button onClick={() => handleLikeAction('like')}
                                                className="px-2 py-2 rounded-full flex items-center">
                                            <BsHeart/>
                                        </button>
                                    )
                            }
                            <span className={"mr-2"}>{likeCount}</span>
                        </div>
                        {
                            isSaved ? (
                                <button onClick={() => handleSaveAction('unsave')}
                                        className="bg-gray-700 px-4 py-2 rounded-full flex items-center">
                                    <CiBookmarkCheck
                                        className={"mr-2"}/>Saved
                                </button>
                            ) : (
                                <button onClick={() => handleSaveAction('save')}
                                        className="bg-gray-700 px-4 py-2 rounded-full flex items-center">
                                    <CiBookmark
                                        className={"mr-2"}/>Save
                                </button>
                            )
                        }
                    </div>
                </div>
                <div className="bg-[#A1A1AA15] p-4 mb-4">
                    <p className="text-sm text-gray-400 mb-2">
                        {viewCount} views • {relativeTime}
                    </p>

                    <div className="flex flex-wrap items-center pb-4 sm:mb-0">
                        {playgroundTags.map((tag, index) => (
                            <a key={index}
                               href={`/explore/${tag}`}
                               className={"mr-1 mb-1 text-[12px] rounded-xl px-2 bg-[#A1A1AA70] text-[#F4F4F580] hover:text-[#F4F4F5] hover:bg-[#A1A1AA] transform duration-300"}>
                                {tag}
                            </a>
                        ))}
                    </div>

                    {
                        item.iframe ? (
                            <Fragment>
                                <div
                                    className={`content-rich-text blog-content w-full break-words ${showFullContent ? '' : 'line-clamp-3'}`}
                                    dangerouslySetInnerHTML={sanitizeHTML(item.description)}
                                />
                                {item.description && (
                                    <button
                                        className="text-blue-400 mt-2 hover:underline focus:outline-none"
                                        onClick={toggleContentVisibility}
                                    >
                                        {showFullContent ? 'Show less' : 'Show more'}
                                    </button>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div
                                    className={`content-rich-text blog-content w-full`}
                                    dangerouslySetInnerHTML={sanitizeHTML(item.description)}
                                />

                                {
                                    item.imageUrl !== "" && (
                                        <div className="relative h-[35vh] overflow-hidden rounded-b-2xl mt-2">
                                            <Link to={item.link} target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={item.imageUrl}
                                                    alt={item.title}
                                                    className="w-full object-cover object-top"
                                                />
                                                <div className="absolute inset-0 bg-black opacity-50"></div>
                                            </Link>
                                        </div>
                                    )
                                }
                            </Fragment>
                        )
                    }

                </div>

                <div onClick={toggleListOfComment}
                     className="border-t border-gray-700 pt-4 hover:bg-[#A1A1AA20] cursor-pointer px-4 pb-3 rounded-xl duration-300">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text font-semibold">Comments {commentList?.length}</h2>
                    </div>
                    <div className="flex items-center">
                        <img src="/assets/images/logo1.png" alt="User Avatar"
                             className="w-8 h-8 rounded-full mr-2"/>
                        <input type="text" placeholder="Add a comment..."
                               className="bg-gray-700 w-full p-2 rounded-full"/>
                    </div>
                </div>
            </div>

            {/*List of comments modal*/}
            <Dialog open={listOfCommentVisible} as="div" className="relative z-10 focus:outline-none"
                    onClose={toggleListOfComment}>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <DialogPanel
                            transition
                            className="w-full max-w-3xl rounded-xl bg-gradient-to-t from-[#121212]/75 to-white/3 p-0 backdrop-blur-xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <DialogTitle as="div"
                                         className="text-base/7 font-medium text-white flex items-center justify-between pt-5 pb-2 px-5 border-b rounded-t border-gray-600">
                                <h3 className="text font-semibold text-[#F4F4F5]">
                                    {commentList?.length} Comments
                                </h3>
                                <button onClick={toggleListOfComment} type="button"
                                        className="text-[#A1A1AA] text-xl rounded-lg w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-white duration-300"
                                        data-modal-hide="default-modal">
                                    <IoClose/>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </DialogTitle>
                            {/*<!-- Modal body*/}
                            <CommentPlaygroundDetail
                                playId={item.id}
                                emailLocal={emailLocal}
                                tokenLocal={tokenLocal}
                                navigation={navigate}
                                commentList={commentList}
                                setCommentList={setCommentList}
                            />

                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default HeaderDetail;