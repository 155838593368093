import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function StructuredData ({ data, url, title, description, imageUrl, item}) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={truncateDescription(description)}/>
            <link rel="canonical" href={url}/>

            <meta property="og:type" content="website"/>
            <meta property="og:url" content={url}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={truncateDescription(description)}/>
            <meta property="og:image" content={imageUrl}/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="630"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:url" content={url}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={truncateDescription(description)}/>
            <meta name="twitter:image" content={imageUrl}/>
            <meta name="twitter:image:alt" content={title}/>

            { item && (
                <>
                    <meta name="author" content={item.author}/>
                    <meta name="article:published_time" content={item.datePublished}/>
                    <meta name="article:modified_time" content={item.dateModified}/>
                </>
            )}

            <script type="application/ld+json">
                {JSON.stringify(data)}
            </script>
        </Helmet>
    );
};

export function truncateDescription(description) {
    if (!description || typeof description !== 'string') {
        return '';
    }
    const maxLength = 170;
    if (description.length <= maxLength) {
        return description;
    }
    return description.slice(0, maxLength - 3) + '...';
}